@tailwind base;
@tailwind components;
@tailwind utilities;




@layer utilities {
    .min-w-10 {
        min-width: 2.5rem;
    }

    .min-w-20 {
        min-width: 10.5rem;
    }

    .max-w-px-120 {
        max-width: 120px;
    }

    .max-w-px-140 {
        max-width: 140px;
    }

    .max-w-px-180 {
        max-width: 180px;
    }

    .max-w-px-280 {
        max-width: 280px;
    }

    .w-800 {
        width: 800px
    }

    .h-100 {
        height: 26.25rem
    }

    .h-420 {
        height: 420px
    }

    .h-350 {
        height: 350px
    }

    .h-500 {
        height: 500px
    }

    .h-310 {
        height: 305px;
    }

    .h-280 {
        height: 280px;
    }

    .h-520 {
        height: 520px
    }

    .h-210 {
        height: 210px
    }

    .h-350 {
        height: 350px
    }
    .h-content {
        height: fit-content;
        }
    .w-680 {
        width: 680px
    }

    .w-300 {
        width: 300px
    }

    .w-17vw {
        width: 17vw
    }

    .h-50vh {
        height: 50vh
    }

    .h-88vh {
        height: 88vh
    }

    .h-90vh {
        height: 90.35vh
    }

    .h-96vh {
        height: 96.5vh
    }

    .h-85vh {
        height: 85vh
    }

    .h-83vh {
        height: calc(100vh - 70px)
    }

    .h-80vh {
        height: 80vh
    }

    .h-24vh {
        height: 24vh
    }

    .h-78vh {
        height: 78vh
    }

    .h-65vh {
        height: 65vh;
    }
    .h-90-percent {
        height: 90%;
    }

    .w-94 {
        width: 94%
    }

    .w-5 {
        width: 5%
    }

    .max-h-50 {
        max-height: 800px;
    }

    .bg-sideBarColor {
        background-color: rgba(30, 30, 45, 1) !important
    }

    .bg-secondary-color {
        background-color: rgba(219, 20, 48, 1) !important
    }
}

.custom-bottom-15 {
    bottom: 10rem;
}

.single-participant-frame {
    width: 100rem;
    max-height: 47rem;
}

.minW-20vw {
    min-width: 20vw;
}

.minW-70vw {
    min-width: 70vw;
}

.minW-28vw {
    min-width: 28vw;
}

.minW-45vw {
    min-width: 45vw;
}

.minW-33vw {
    min-width: 33.5vw;
}





.video-wrapSingle {
    width: 30vw;
    height: 50vh;
    /* max-height: 350px; */
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

.video-wrapSingle>video {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    /* min-height: 100%;
    min-width: 100%; */
    transform: translate(-50%, -50%);
}

.video-wrap {
    width: 25vw;
    height: 40vh;
    /* max-height: 350px; */
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

.video-wrap>video {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    /* min-height: 100%;
    min-width: 100%; */
    transform: translate(-50%, -50%);
}

/* @media screen and (max-width: 414px) {
    .single-participant-frame {
        width: 100rem;
        height: 92rem;
        border: 1px;
    }

    .local-participant {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 30%;
        margin: 9px 4px;
        border: 1px;
    }
}

.videoParticipant {
    width: 40rem !important;
    height: 40rem !important;
} */

/* video {
    min-width: 100% !important;
    object-fit: cover !important;
    height: 90% !important;
    max-height: 91% !important;
} */
/* overriding classess */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
}
/* input[type=number] {
    -moz-appearance: textfield;
  } */
@media screen and (max-width : 600px) {
    .desktopParticipant {
        display: none !important;
    }

    .mobileParticipant {
        display: flex !important;
    }

    .video-wrapSingle {
        width: 100vw;
        height: 35vh;
        max-height: 350px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .singleParticipant {
        width: 48vw !important;
    }

    .video-wrap {
        width: 100vw;
        height: 30vh;
        max-height: 350px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin: 0;
    }

    .total_particpiants {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 70% 30%;
        /* background-color: #dc3a4e; */
    }

    .remote_participiants {
        /* background-color: rgb(207, 227, 20); */
        align-self: flex-start;
        height: 100%;
    }

    .local_participiants {
        /* background-color: aquamarine; */
        position: absolute;
        bottom: 0%;
        right: 0;
        display: block;
        grid-template-columns: repeat(1, 1fr);
    }

    .local_participiants video {
        width: 170px;
        height: auto;

    }
}

@media screen and (min-width: 1160) {
    body {
        background-color: red;
    }

}

.desktopParticipant {
    display: flex;
}

.mobileParticipant {
    display: none;
}



.leaflet-container {
    height: 30vh;
    border-radius: 5px;
    margin-top: 10px;
    z-index: 0;
}

.tab.tab-active {
    color: #dc3a4e !important
}

.tab.dashboard-tab {
    color: #dc3a4e;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-offset-shadow: none !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    border-color: #dc3a4e;
}

.hide-scroller::-webkit-scrollbar {
    display: none;
}

.hide-scroller-firefox {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #c2c2c2 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    /* border-radius: 10px; */
    border: 3px solid #ffffff;
}


/* radion button css */
input[type="radio"] {



    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 3px;

    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type=number] {
    -moz-appearance: textfield;
  }
/* appearance for checked radiobutton * */
input[type="radio"]:checked {
    background-image: none !important;
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type="radio"]:checked:hover {
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type="radio"]:checked:active {
    background-color: #F44336;
    border: 2px solid #F44336;
}


input[type="radio"]:checked:focus {
    background-color: #F44336;
    border: 2px solid #F44336;
}

input[type='radio']:focus {
    outline: 0px !important;
    outline-offset: unset !important;
    outline-style: unset !important;
    box-shadow: none !important;
    outline-color: unset !important;
}

:root {
    background-color: hsla(var(--b1)/var(--tw-bg-opacity, 1));
    color: black;
    --animation-curve: cubic-bezier(0.12, 0.7, 0.74, 0.71);

}

.fa-rotate-45 {
    -ms-transform: rotate(-45deg);
    /* Internet Explorer 9 */
    -webkit-transform: rotate(-45deg);
    /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    /* Standard syntax */
}

.animate-call {
    animation: callScaling 1.7s cubic-bezier(0, 0, 0.2, 1) infinite;


}


@keyframes callScaling {

    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}


/* tabs colors */
.tab-lifted.tab-active {
    --tw-bg-opacity: 1;
    background-color: white;
    border-left-width: var(--tab-border, 1px);
    border-bottom-width: 0;
    border-right-width: var(--tab-border, 1px);
    border-top-width: var(--tab-border, 1px);
    border-color: #d1d5db;
    /* border-left-color: hsla(var(--border-color,var(--b3))/var(--tw-border-opacity,1));
    border-right-color: hsla(var(--border-color,var(--b3))/var(--tw-border-opacity,1));
    border-top-color: hsla(var(--border-color,var(--b3))/var(--tw-border-opacity,1)); */
    padding-left: calc(var(--tab-padding, 1rem) - var(--tab-border, 1px));
    padding-right: calc(var(--tab-padding, 1rem) - var(--tab-border, 1px));
    padding-bottom: var(--tab-border, 1px);
    padding-top: 0;
}

.tab-lifted {
    border-top-left-radius: var(--tab-radius, .5rem);
    border-top-right-radius: var(--tab-radius, .5rem);
    border: 0 solid transparent;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    /* border-bottom: #d1d5db; */
    border-bottom: var(--tab-border, 1px) solid #d1d5db;
    padding-left: var(--tab-padding, 1rem);
    padding-right: var(--tab-padding, 1rem);
    padding-top: var(--tab-border, 1px);
}

.tab {
    cursor: pointer;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 2rem;
    font-size: .875rem;
    line-height: 1.25rem;
    line-height: 2;
    --tab-padding: 1rem;
    --tw-text-opacity: 1;
    --tw-text-opacity: 0.5;
    color: #8f94a1;
    padding-left: var(--tab-padding, 1rem);
    padding-right: var(--tab-padding, 1rem);
}

.label-text {
    font-size: .875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: black;
}

.checkbox {
    --chkbg: var(--bc);
    --chkfg: var(--b1);
    -webkit-appearance: none;
    appearance: none;
    --tw-border-opacity: 1;
    --tw-border-opacity: 0.2;
    border-color: #8f94a1;
    border-width: 1px;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: var(--rounded-btn, .5rem);
}

.custom-checkbox{
    --chkbg: var(--bc);
    --chkfg: var(--b1);
    -webkit-appearance: none;
    appearance: none;
    --tw-border-opacity: 1;
    --tw-border-opacity: 0.2;
    border-color: #8f94a1;
    border-width: 1px;
    cursor: pointer;
    height: 1rem !important;
    width: 1rem !important;
    border-radius: var(--rounded-btn, .5rem);
}

.center {
    /* background-color: black; */
    display: flex;
}

.react-select-16-listbox {
    z-index: 99999 !important;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.css-6j8wv5-Input {
    margin: 0px !important;
    padding-bottom: 0px !important;
}

.css-1pndypt-Input {
    margin: 0 !important;
    padding-bottom: 0 !important;
}

.multi-range-slider {
    display: flex;
    position: relative;
    border: none !important;
    border-radius: none !important;
    padding: 20px 10px;
    box-shadow: none !important;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.multi-range-slider .ruler {
    margin: 10px 0px -5px 0px;
    display: flex;
    display: none !important;
}

.multi-range-slider .bar-inner {
    background-color: #3f83f8 !important;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    border: none !important;
    justify-content: space-between;
    box-shadow: none !important;
}

.multi-range-slider .thumb::before {
    content: '';
    background-color: white;
    position: absolute;
    width: 20px;
    height: 20px;
    border: none !important;
    box-shadow: 0px 0px 3px black, inset 0px 0px 5px grey;
    border-radius: 50%;
    z-index: 1;
    margin: -8px;
    cursor: pointer;
}

.multi-range-slider .bar-right {
    width: 25%;
    background-color: #f0f0f0;
    border-radius: 0px 10px 10px 0px;
    box-shadow: inset 0px 0px 1px black !important;
}

.multi-range-slider .bar-left {
    width: 25%;
    background-color: #f0f0f0;
    border-radius: 10px 0px 0px 10px;
    box-shadow: inset 0px 0px 1px black !important;
    padding: 4px 0px;
}

.videoParticipant {
    flex: 1 0 42%;
}

.checkbox {
    --chkbg: var(--bc);
    --chkfg: var(--b1);
    -webkit-appearance: none;
    appearance: none;
    --tw-border-opacity: 1;
    --tw-border-opacity: 0.2;
    border-color: #8f94a1;
    border-width: 1px;
    cursor: pointer;
    height: 1.3rem !important;
    width: 1.3rem !important;
    border-radius: 0.5rem;
    border-radius: var(--rounded-btn, .5rem);
}

.gradient-1 {
    background-image: linear-gradient(to right, #39cfbd, #00c4c9, #00b8d1, #00aad5, #119cd4);
}

.gradient-2 {
    background-image: linear-gradient(to right, #866fe8, #7f71e7, #7872e5, #7274e4, #6b75e2, #6476e0, #5d77de, #5678db, #4d7ad7, #467bd3, #3f7ccf, #3a7dca);
}

.gradient-3 {
    background-image: linear-gradient(to right, #cf69e7, #c066ec, #af65f0, #9b64f5, #8363f9);
}

.gradient-4 {
    background-image: linear-gradient(to right, #f4815a, #f77d60, #fa7966, #fc756d, #fe7274, #ff6f7b, #ff6d82, #ff6b89, #ff6991, #ff689a, #ff67a3, #fd67ac);
}

.gradient-5 {
    background: hsla(145, 84%, 73%, 1);

    background: linear-gradient(90deg, hsla(145, 84%, 73%, 1) 0%, hsla(150, 61%, 48%, 1) 100%);

    background: -moz-linear-gradient(90deg, hsla(145, 84%, 73%, 1) 0%, hsla(150, 61%, 48%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(145, 84%, 73%, 1) 0%, hsla(150, 61%, 48%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#82F4B1", endColorstr="#30C67C", GradientType=1);
}

.gradient-6 {
    background: hsla(350, 93%, 61%, 1);

    background: linear-gradient(90deg, hsla(350, 93%, 61%, 1) 0%, hsla(8, 98%, 59%, 1) 100%);

    background: -moz-linear-gradient(90deg, hsla(350, 93%, 61%, 1) 0%, hsla(8, 98%, 59%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(350, 93%, 61%, 1) 0%, hsla(8, 98%, 59%, 1) 100%);
}

/* for input range appearance */
.input-range-appearance {
    -webkit-appearance: button !important
}

.input-range-red {
    --range-shdw: 11 100% 50%
}

.input-range-orange {
    --range-shdw: 1111 100% 50%
}

.input-range-green {
    --range-shdw: 111 100% 50%
}

.min-h-96 {
    min-height: 24rem;
}

.rdw-suggestion-dropdown{
    width: 250px;
    /* background-color: #3a7dca !important; */
    min-height: auto;
}
.rdw-suggestion-dropdown::-webkit-scrollbar {
    display: none;
}


.hide-toolbar { display:none !important; } 


.loader-animation {
    width: 4vmax;
    height: 4vmax;
    border-right: 4px solid hsla(355, 93%, 61%, 1);
    border-radius: 100%;
    -webkit-animation: spinRight 800ms linear infinite;
            animation: spinRight 800ms linear infinite;
  }
  .loader-animation:before, .loader-animation:after {
    content: "";
    width: 3vmax;
    height: 3vmax;
    display: block;
    position: absolute;
    top: calc(50% - 1.5vmax);
    left: calc(50% - 1.5vmax);
    border-left: 3px solid hsla(30, 93%, 61%, 1);
    border-radius: 100%;
    -webkit-animation: spinLeft 1000ms linear infinite;
            animation: spinLeft 1000ms linear infinite;
  }
  .loader-animation:after {
    width: 1vmax;
    height: 1vmax;
    top: calc(50% - 0.5vmax);
    left: calc(50% - 0.5vmax);
    border: 0;
    border-right: 2px solid hsla(355, 93%, 61%, 1);
    -webkit-animation: none;
            animation: none;
  }
  
  @-webkit-keyframes spinLeft {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
  }
  
  @keyframes spinLeft {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(720deg);
    }
  }
  @-webkit-keyframes spinRight {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes spinRight {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }